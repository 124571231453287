import GtranslateAction from './actions/GtranslateAction'
import FooterTopComponent from './components/footer-top.component'
import IgWallComponent from './components/front-page/ig-wall.component'
import SliderExperiencesComponent from './components/front-page/slider-experiences.component'
import SwitchComponent from './components/front-page/switch.component'
import MobileMenuComponent from './components/mobile-menu.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    // General
    new NavbarComponent()
    new MobileMenuComponent()
    new FooterTopComponent()
    new GtranslateAction()

    // Frontpage
    new SwitchComponent()
    new SliderExperiencesComponent()
    new IgWallComponent()
})
