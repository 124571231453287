import apiFetch from '@wordpress/api-fetch'

export default class IgWallComponent {
    constructor() {
        if ($('[data-id="social-wall"]').length > 0) {
            const limit = window.matchMedia('(min-width: 651px)').matches
                ? 3
                : window.matchMedia('(min-width: 371px)').matches
                  ? 2
                  : 1

            apiFetch({
                path: Customer.iris_sso_instagram_path,
                method: 'POST',
                data: { limit: limit },
            })
                .then((result) => {
                    $('[data-id="social-wall"]').html(result.render)
                    import(
                        '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                    ).then(({ HcLazyload }) => {
                        HcLazyload.update()
                    })
                })
                .catch(() => {
                    $('[data-id="social-wall"]').html('')
                })
        }
    }
}
