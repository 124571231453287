import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class SliderExperiencesComponent {
    constructor() {
        const slider = '#strate__experiences__slider'
        if ($(slider).length > 0) {
            Swiper.create('#strate__experiences__slider__thumbs', {
                slidesPerView: 'auto',
                allowTouchMove: false,
            }).then(async (slider_pagination) => {
                await Swiper.create(slider, {
                    mousewheel: {
                        forceToAxis: true,
                    },
                    speed: 500,
                    slidesPerView: 1.05,
                    spaceBetween: 15,
                    lazy: true,
                    grabCursor: true,
                    thumbs: {
                        swiper: slider_pagination,
                    },
                    navigation: {
                        prevEl: '.hc-slider-button-prev',
                        nextEl: '.hc-slider-button-next',
                    },
                    breakpoints: {
                        521: {
                            slidesPerView: 1.5,
                            spaceBetween: 20,
                        },
                        651: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1001: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            loop: true,
                        },
                    },
                })
            })
        }
    }
}
