export default class GtranslateAction {
    constructor() {
        GtranslateAction.translate()
    }

    static translate() {
        $('.gtranslate-list a').on('click', (e) => {
            updateCurrentLanguageTopbar()
        })

        const updateCurrentLanguageTopbar = () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')

                switch (current_language) {
                    case 'en':
                        current_language = 'English'
                        break
                    case 'fr':
                        current_language = 'Français'
                        break
                    case 'nl':
                        current_language = 'Nederlands'
                        break
                    case 'de':
                        current_language = 'Deutsch'
                        break
                    case 'auto':
                        current_language = 'Français'
                        break
                }

                $('.header-menu-top-lang--current').text(current_language)

                $('.glink').each(function () {
                    if (
                        $(this).text() === current_language ||
                        ($(this).text() === 'French' && current_language === 'Français')
                    ) {
                        $(this).css('display', 'none')
                    } else {
                        $(this).css('display', 'block')
                    }
                })
            }, 500)
        }

        $(window).on('load', () => {
            setTimeout(() => {
                updateCurrentLanguageTopbar()
            }, 500)
        })
    }
}
