export default class MobileMenuComponent {
    constructor() {
        $('[data-trigger="wpiris-menu-toggle"]').on('click', () => {
            $('body > .wrapper').toggleClass('menu-opened')
        })

        $('[data-trigger="header-menu-trigger-submenu"]').on('click', function () {
            $(this).parent().toggleClass('submenu-active')
        })
    }
}
