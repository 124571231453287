export default class footerTopComponent {
    constructor() {
        footerTopComponent.position()

        $(window).resize(() => {
            footerTopComponent.position()
        })
    }

    static position() {
        if (window.matchMedia('(min-width: 1001px)').matches) {
            const footerTop_height = $('.footer-top').height()
            $('body:not(.home) main#main, body.home .strate__territoire').css(
                'padding-bottom',
                `${footerTop_height / 2}px`,
            )
            $('.footer-main-background').css('padding-top', `${footerTop_height / 2}px`)
            $('.footer-top').css('top', '0')
        } else {
            const footer_newsletter_height = $('.footer-newsletter').height()
            const footer_brochures_height = $('.footer-brochures').height()
            $('body:not(.home) main#main, body.home .strate__territoire').css(
                'padding-bottom',
                `${footer_newsletter_height}px`,
            )
            $('.footer-main-background').css('padding-top', `${footer_brochures_height}px`)
            $('.footer-top').css('top', -(footer_newsletter_height - footer_brochures_height) / 2)
        }
    }
}
