export default class NavbarComponent {
    constructor() {
        let last_scroll = 0
        let direction = null
        const $element = $('.header-navbar')
        const $body = $('body > .wrapper')
        const trigger_height = $element.outerHeight()
        const $pageInitTop = $(window).scrollTop()

        if ($pageInitTop > 40) {
            $body.addClass('is-hidden')
        }

        $(window).scroll(() => {
            const scroll = $(window).scrollTop()

            if (scroll > last_scroll) {
                direction = 'bottom'
                last_scroll = scroll
            } else {
                direction = 'top'
                last_scroll = scroll
            }

            if (
                direction === 'bottom' &&
                scroll > trigger_height &&
                !$element.is(':hover') &&
                !$body.hasClass('menu-opened')
            ) {
                $body.removeClass('is-sticky')
                $body.addClass('is-hidden')
            } else {
                $body.addClass('is-sticky')
                $body.removeClass('is-hidden')
            }

            if (scroll <= 40) {
                $body.removeClass('is-sticky')
            }
        })
    }
}
